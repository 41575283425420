// @ts-nocheck
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { Granim } from "../Granim"
import { rgbToHexString, rgbToHsv } from "@lib/utils/color"
import { setDeviceColor } from "@store/action-creators"
import { getDevices, getUserUid } from "@store/selectors"
import { DevicesState } from "@store/reducers/device.reducer"
import { RequestSetDeviceColor } from "@store/saga-creators/device-saga.creator"
import { FormattedMessage } from "react-intl"
import { isEmpty } from "lodash"
import { hslToHex } from "@lib/utils"
import { classicRepresentation } from "@lib/constants/themes"

const styles = require("./card.module.less")

type Props = {
    id: number
    colors: Array<number[]>
    name: string
}
const ThemeCard = React.memo((props: Props) => {
    const [colors, setColors] = useState([] as Array<string[]>)
    const devices = useSelector(getDevices, shallowEqual)
    const dispatch = useDispatch()
    const userUid = useSelector(getUserUid)

    useEffect(() => {
        const { colors, name } = props
        mapColors(name === "classic" ? classicRepresentation : colors)
    }, [])

    const mapColors = (colors: Props["colors"]) => {
        if (!isEmpty(colors)) {
            const formatedColors = colors
                .map(color => hslToHex(color[0], color[1] || 100, 50))
                .reduce((result, value, index, array) => {
                    if (index % 2 === 0)
                        result.push(array.slice(index, index + 2))
                    return result
                }, [] as Array<string[]>)
            console.log(formatedColors)
            setColors(formatedColors)
        }
    }
    const showTheme = async () => {
        const { colors } = props
        const hsv = rgbToHsv(125, 255, 125)
        let i = 0
        console.log(colors)
        // setDeviceColor(socket, 3, )
        for await (let { deviceId, brightness } of Object.values(devices)) {
            let colorIndex = i % colors.length
            const hsvColor = {
                hue: colors[colorIndex][0] / 360,
                saturation: colors[colorIndex][1] / 100,
                brightness: colors[colorIndex][2] / 100 || brightness || 0.5
            }
            setTimeout(() => {
                dispatch(RequestSetDeviceColor({ deviceId, hsvColor, userUid }))
            }, 250 * i)
            i++
        }
    }
    console.log("rendering theme card")
    if (colors.length < 1) return <div />
    return (
        <div onClick={showTheme}>
            <h1 className={styles["card-title"]}>
                <FormattedMessage id={`app.theme.${props.name}`} />
            </h1>
            <Granim
                style={{
                    // TODO: put into CSS file
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    borderRadius: 10,
                    top: 0
                }}
                id={`granim-${props.id}`}
                direction={"diagonal"}
                states={{
                    "default-state": {
                        gradients: colors
                    }
                }}
            />
        </div>
    )
})

export default ThemeCard
