import {
  REQUEST_SET_DEVICE_STATUS,
  REQUEST_SET_DEVICE_COLOR,
  REQUEST_GET_AVAILABLE_DEVICES
} from "@store/saga-types";
import { SetDeviceStatusPayload, SetDeviceColorPayload, GetAvailableDevicesPayload } from "@store/models";

export const RequestSetDeviceStatus = (payload: SetDeviceStatusPayload) => ({
  type: REQUEST_SET_DEVICE_STATUS,
  payload
});
export const RequestSetDeviceColor = (payload: SetDeviceColorPayload) => ({
  type: REQUEST_SET_DEVICE_COLOR,
  payload
});
export const RequestGetAvailableDevices = (payload: GetAvailableDevicesPayload) => ({
  type: REQUEST_GET_AVAILABLE_DEVICES,
  payload
})
