import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ColorWheel } from "@common/components/ColorWheel"
import { rgbToHsv } from "@lib/utils/color"
import {
    getSelectedDevice,
    getSelectedDeviceColor,
    getUserUid
} from "@store/selectors"
import { RequestSetDeviceColor } from "@store/saga-creators"

type Props = {}
type color = { r: number; g: number; b: number }

const DeviceModalContent = (props: Props) => {
    const dispatch = useDispatch()
    const selectedDevice = useSelector(getSelectedDevice)
    const selectedColor = useSelector(getSelectedDeviceColor)
    const [prevColor, setPrevColor] = useState({} as color)
    const userUid = useSelector(getUserUid)
    const onSettingsChange = (setting: string, value: color) => {
        switch (setting) {
            case "color":
                const hsv = rgbToHsv(value.r, value.g, value.b)

                const hsvColor = {
                    hue: hsv[0] ? hsv[0] : 0,
                    saturation: hsv[1] ? hsv[1] : 0,
                    brightness: hsv[2] ? hsv[2] : 0
                }
                if (prevColor !== value) {
                    setPrevColor(value)
                    console.log({ selectedDevice, value, prevColor })

                    dispatch(
                        RequestSetDeviceColor({
                            deviceId: selectedDevice.deviceId,
                            hsvColor,
                            userUid
                        })
                    )
                }
                break
            default:
                break
        }
    }
    return (
        <div
            style={{
                placeSelf: "center",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%"
            }}
        >
            <ColorWheel
                color={selectedColor}
                onColorChange={rgbColor => onSettingsChange("color", rgbColor)}
            />
        </div>
    )
}

export { DeviceModalContent }
