import React, { useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FormattedMessage } from "react-intl"
import QueueAnim from "rc-queue-anim"
import { Card } from "@common/components/_Card/Card"
import { Carousel } from "@common/components/Carousel"
import { SectionHeader } from "@common/components/SectionHeader/SectionHeader"
import { Grid } from "@common/components/Grid/Grid"
import { getDevices, getUserUid, getWindowDimensions } from "@store/selectors"
import { Device } from "@lib/models"
import { themes } from "@lib/constants/themes"
import { withRouter, RouteComponentProps } from "react-router"
import { devices as mockDevices } from "@lib/constants/devices"
import { config } from "@lib/constants"
import { IconCard } from "@common/components/_Card"
import { RequestSetDeviceColor } from "@store/saga-creators"
import { clamp } from "lodash"
const styles = require("./room.module.less")

const animConfig = [
    { opacity: [1, 0], translateX: [0, 50] },
    { opacity: [1, 0], translateX: [0, -50] }
]
type Props = {} & RouteComponentProps
const HomeScreen = withRouter((props: Props) => {
    const devices = useSelector(getDevices)
    const dispatch = useDispatch()
    const userUid = useSelector(getUserUid)
    const dimensions = useSelector(getWindowDimensions)

    const setDeviceColor = (device: Device, brightness: number) => {
        const { deviceId, hue, saturation } = device
        const hsvColor = { hue, saturation, brightness }
        dispatch(RequestSetDeviceColor({ deviceId, userUid, hsvColor }))
    }
    const onBrightnessPresetClick = async item => {
        switch (item.title) {
            case "0%":
                Object.values(devices).forEach((device, i) => {
                    setTimeout(() => setDeviceColor(device, 0), 250 * i)
                })
                break
            case "50%":
                Object.values(devices).forEach((device, i) => {
                    setTimeout(() => setDeviceColor(device, 0.5), 250 * i)
                })
                break
            case "100%":
                Object.values(devices).forEach((device, i) => {
                    setTimeout(() => setDeviceColor(device, 1), 250 * i)
                })
                break
        }
    }
    const render = () => (
        <QueueAnim delay={100} interval={100}>
            <h1 className={styles["headline"]}>
                <FormattedMessage id="app.room.headline" />
            </h1>
            <SectionHeader
                key="queue-anime-1"
                title={<FormattedMessage id="app.room.title" />}
            />
            <Carousel slideWidth={0.65} key="queue-anime-2">
                {themes.map((theme, i) => (
                    <Card
                        id={i}
                        size={"lg"}
                        key={i}
                        type="theme"
                        theme={theme}
                    />
                ))}
            </Carousel>
            <SectionHeader
                key="queue-anime-7"
                title={<FormattedMessage id="app.brightness" />}
            />
            <Grid
                key="queue-anim-6"
                minItemSize={100}
                items={[
                    { icon: "brightness1", title: "0%" },
                    { icon: "brightness2", title: "50%" },
                    { icon: "brightness3", title: "100%" }
                ]}
                renderItem={(item, i) => (
                    <IconCard
                        icon={item.icon}
                        title={item.title}
                        color="@white"
                        type="custom"
                        handleclick={() => onBrightnessPresetClick(item)}
                        shape="round"
                        size={clamp(dimensions.width * 0.18, 50, 96)}
                    />
                )}
            />
            <SectionHeader
                key="queue-anime-4"
                title={<FormattedMessage id="app.lights.title" />}
            />
            <Grid
                key="queue-anime-5"
                minItemSize={clamp(window.innerWidth, 1, 300)}
                items={Object.values(
                    config.env === "prod" ? devices : devices
                ).map(item => ({ ...item }))}
                renderItem={(item, i) => {
                    const device = item as Device
                    return (
                        <Card
                            size={"md"}
                            type="device"
                            title={
                                <FormattedMessage
                                    id={`app.device.${
                                        device.deviceName
                                            ? device.deviceName.toLowerCase()
                                            : ""
                                    }`}
                                    defaultMessage={
                                        device.deviceName || "Device"
                                    }
                                />
                            }
                            device={device}
                            key={i}
                        />
                    )
                }}
            />
        </QueueAnim>
    )

    return <div className={`${styles["home-container"]}`}>{render()}</div>
})

export default HomeScreen
