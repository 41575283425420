import feathers from "@feathersjs/feathers"
import socketio from "@feathersjs/socketio-client"
import auth from "@feathersjs/authentication-client"
import io from "socket.io-client"

import {
    fetchAvailableDevices,
    addMessagingObject,
    deviceLost
} from "@store/action-creators/device-action.creator"
import { config } from "@lib/constants"
import {
    ChangeAuthState,
    AddInternalizationService
} from "@store/action-creators"
import { RequestGetAvailableDevices } from "@store/saga-creators"

const initConnectionManager = (dispatch, token) => {
    const query = "?client=user&token=" + token
    var url =
        config.env === "prod"
            ? "https://cloud.meshle.com" + query
            : "https://cloud.meshle.com" + query

    /**************************************************/
    const socket = io(url, {
        transports: ["websocket"],
        upgrade: false
    })
    const feathersClient = feathers()

    feathersClient.configure(socketio(socket))
    feathersClient.configure(auth())

    const dataMessagesService = feathersClient.service("datamessages")
    const internalizationService = feathersClient.service(
        "internationalization"
    )
    let tokenFromServer
    let messagingObjects

    dataMessagesService.on("created", message =>
        handleStatusEvent(dispatch, message)
    )

    const authOptions = {
        strategy: "tokenLocal",
        token: token
    }

    feathersClient
        .authenticate(authOptions)
        .then(packet => {
            tokenFromServer = packet.token
            messagingObjects = {
                dataMessagesService,
                token: tokenFromServer.token,
                gatewayID: tokenFromServer.gatewayID
            }
            dispatch(addMessagingObject(messagingObjects))
            dispatch(
                ChangeAuthState({ authenticated: true, authenticating: false })
            )
            dispatch(AddInternalizationService({ internalizationService }))
            dispatch(
                RequestGetAvailableDevices({ messagingObjects, userUid: "" })
            )
            console.log("====================================")
            console.warn(`Authentication successful`, messagingObjects)
            // console.error(`Authentication successful. Access-Token=${packet.accessToken}`);
            console.log("====================================")

            // datamessageService.on('created', message => console.log(`>>>>>>>>>>>>>>>>>>>>>>>>>CREATE MESSAGE RECEIVED: ${message}`));
            // datamessageService.on('updated', message => console.log(`>>>>>>>>>>>>>>>>>>>>>>>>>UPDATE MESSAGE RECEIVED: ${message}`));

            // var status = false;
            // setInterval(function() {
            // let params = { query: { gatewayID: tokenFromServer.gatewayID }, mongoose: { upsert: true } };
            // let data = {token: tokenFromServer.token, gatewayID: tokenFromServer.gatewayID, event: "setStatus", payload: {"deviceType":1,"deviceId":3,"status":status,"duration":10}};
            // status = true;
            // // datamessageService.patch(null, data, params)
            // datamessageService.create(data)
            //   .then((message) => {
            //     console.log(`>>>CREATE SUCCESS: ${JSON.stringify(message)}`);
            //   })
            //   .catch((error) => {
            //     console.log(`>>>CREATE FAILURE - ${JSON.stringify(error)}`);
            //   });
            // }, 20 * 1000);
        })
        .catch(error => {
            console.log("====================================")
            console.error(`Authentication error`, error)
            dispatch(
                ChangeAuthState({ authenticated: false, authenticating: false })
            )
            console.log("====================================")
        })

    feathersClient
        .reAuthenticate()
        .then(() => {
            console.log("====================================")
            console.warn(`ReAuthentication successful`)
            console.log("====================================")
            // show application page
        })
        .catch(error => {
            console.log("====================================")
            console.error(`ReAuthentication error`, error)
            console.log("====================================")
        })

    /**************************************************/

    //const { handleServerStatusMessage } = makeResponseHandlers(socket)
    // socket.on("status", data => dispatch(fetchAvailableDevices(data)));

    return { messagingObjects }
}

const handleStatusEvent = (dispatch, message) => {
    console.log("Status", { message })
    let { event, payload } = message
    switch (event) {
        case "status":
            dispatch(fetchAvailableDevices(payload))
            break
        case "bt_lost":
            dispatch(deviceLost(payload))
            break
    }
}

export default initConnectionManager
